<template>
    <dialog v-show="isOpen" ref="dialogRef" class="privacy-dialog">
        <div class="text-center flex flex-col gap-3 items-center tracking-tighter">
            <div><i class="bi bi-lock text-2xl" /></div>
            <div class="w-full font-semibold text-2xl text-[#262626]">Your privacy is important to us</div>
            <MarkdownText v-if="organizationDetails.custom_privacy_modal_message" :text="organizationDetails.custom_privacy_modal_message"></MarkdownText>
            <div v-else class="w-full font-medium text-base text-[#8C8C8C] w-96 mx-auto">
                Read our full <a ref="external" class="hover:text-[#4B508F] text-[#555BA2]" href="https://www.valence.co/other/privacy" target="_blank">privacy policy</a> and
                <a ref="external" class="hover:text-[#4B508F] text-[#555BA2]" href="https://www.valence.co/other/ai-ethics-pledge" target="_blank">AI Ethics Pledge</a> to
                understand how we protect and handle your information.
            </div>
        </div>
        <div class="inline-block mx-auto">
            <button :autofocus="isOpen" type="button" class="button button-primary" @click="closeDialog">Dismiss</button>
        </div>
    </dialog>
    <button type="button" :disabled="disabled" class="button button-text button-text--muted" @click="openDialog">{{ buttonText }} <i class="bi bi-lock" /></button>
</template>

<script setup>
import MarkdownText from "~vue/MarkdownText.vue";
import { inject, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

defineProps({
    disabled: Boolean,
    buttonText: { type: String, default: "Private" },
});
const isOpen = ref(false);
const dialogRef = useTemplateRef("dialogRef");
const organizationDetails = inject("organization_details");

watch(isOpen, (value) => {
    if (value) {
        dialogRef.value.showModal();
    } else {
        dialogRef.value.close();
    }
});

onMounted(() => {
    if (dialogRef.value) {
        dialogRef.value.addEventListener("cancel", handleDialogCancel);
    }
});

onUnmounted(() => {
    if (dialogRef.value) {
        dialogRef.value.removeEventListener("cancel", handleDialogCancel);
    }
});

function handleDialogCancel(e) {
    e.preventDefault();
    closeDialog();
}

function openDialog() {
    isOpen.value = true;
}

function closeDialog() {
    isOpen.value = false;
}
</script>

<style scoped>
.privacy-dialog {
    @apply top-auto bottom-0 md:top-0 rounded-tl-3xl rounded-tr-3xl md:rounded-3xl bg-white px-3 py-5 md:px-6 md:py-10 mx-auto w-full max-w-full md:max-w-md w-full flex flex-col gap-10 items-center;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

dialog.privacy-dialog::backdrop {
    background: rgba(23, 55, 100, 0.4);
    backdrop-filter: blur(4px);
}
</style>
